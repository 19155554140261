import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
const PrivacyPage = () => (


    <Layout pageInfo={{ pageName: "privacy" }}>
        <Seo title="Privacy Policy" />
        <div class=" w-100 ">
            <section class="mt-nav">
                <h1>Privacy Policy</h1>
            </section>
            <section class="privacy-section--full privacy-section--intro">
                <div class="legal-inner-container">
                    <p>We believe that transparency is the key to any good relationship. At Smilesolver we appreciate that you are trusting us with information that is important to you, and we want to be transparent about how we use it.</p>
                    <p>Here we describe the privacy practices for our devices, applications, software, websites, APIs, products, and services (the “Services”). You will learn about the data we collect, how we use it, the controls we give you over your information, and the measures we take to keep it safe.</p>
                    <p>Specifically, we’ll cover:</p>
                </div>
            </section>
            <section class="privacy-section--body">
                <div class="legal-inner-container">
                    <ul>
                        <li><a href="#info-we-collect">Information We Collect</a></li>
                        <li><a href="#how-we-use-info">How We Use Information</a></li>
                        <li><a href="#how-info-is-shared">How Information Is Shared</a></li>
                        <li><a href="#your-rights-to-access">Your Rights To Access and Control Your Personal Data</a></li>
                        <li><a href="#data-retention">Data Retention</a></li>
                        <li><a href="#analytics-and-advertising">Analytics and Advertising Services Provided by Others</a></li>
                        <li><a href="#info-security">Information Security</a></li>

                        <li><a href="#changes-to-this-policy">Changes to This Policy</a></li>
                        <li><a href="#contact-us">Who We Are and How To Contact Us</a></li>
                    </ul>
                    <hr />
                    <h2 id="info-we-collect" data-heading="">Information We Collect</h2>

                    <p>When you use our Services, we collect the following types of information.</p>
                    <h3>Information You Provide Us</h3>

                    <div class="tabbed">
                        <h3>Account Information</h3>
                        <p>Some information is required to create an account on our Services, such as your name, email address, password, GDC number, and in some cases your mobile telephone number. This is the only information you have to provide to create an account with us. You may also choose to provide other types of information, such as a profile photo, biography, country information, and community username.</p>
                        <h3>Additional Information</h3>
                        <p>To help improve your experience or enable certain features of our Services, you may choose to provide us with additional information, like your logs for activity, signins, or production tracking; an alarm; and messages on discussion boards or communications to and from dentists and other 3rd parties on our platform.</p>
                        <p>You may also connect with friends on the Services or invite friends who have not yet joined by providing their email addresses, accessing social networking accounts, or using the contact list on your mobile device. We do not store your contact list and delete it after it is used for adding contacts as friends.</p>
                        <p>If you contact us or participate in a survey, contest, or promotion, we collect the information you submit such as your name, contact information, and message.</p>
                        <h3>Information from Third-Party Services</h3>
                        <p>If you choose to connect your account on our Services to your account on another service, we may receive information from the other service. For example, if you connect to Facebook or Google, we may receive information like your name, profile picture, age range, language, email address, and friend list. You may also choose to grant us access to your business or activity data from another service. You can stop sharing the information from the other service with us by removing our access to that other service.</p>
                        <h3>Payment and Card Information</h3>
                        <p>Some Labtrac services support payments and transactions with third parties. If you activate this feature, you must provide certain information for identification and verification, such as your name, credit, debit or other card number, card expiration date, and CVV code. This information is encrypted and sent to your card network, which upon approval sends back to your device a token, which is a set of random digits for engaging in transactions without exposing your card number. For your convenience, we store the last four digits of your card number and your card issuer’s name and contact information. You can remove the token from your account using your account settings. We do not store your transaction history.</p>
                        <p>If you purchase Labtrac services on our website, you provide your payment information, including your name, credit or debit card number, card expiration date, CVV code, and billing address. We do not store this payment information. We store your invoice address to fulfill your order. Note that third-party payment processors may retain this information in accordance with their own privacy policies and terms.</p>
                    </div>

                    <h3>Information We Receive From Your Use of Our Services</h3>
                    <div class="tabbed">
                        <h3>Business Information</h3>
                        <p>
                            Your software collects data to provide ordering and invoicing functionality, patient information, production capacity, workflow, productivity performance, financial information, staff skills and effectiveness, active periods, and location.
                            The data collected varies depending on which service you use.
                            When your software syncs with other applications or software, data recorded on your devices is transferred from your system to our servers.
                </p>
                        <h3>Location Information</h3>
                        <p>The Services include features that use precise location data, including GPS signals, device sensors, Wi-Fi access points, and cell tower IDs. We collect this type of data if you grant us access to your location. You can always remove our access using your Labtrac device or mobile device settings. We may also derive your approximate location from your IP address.</p>
                        <h3>Usage Information</h3>
                        <p>
                            When you access or use our Services, we receive certain usage data.
                            This includes information about your interaction with the Services, for example, when you view or search content, install applications or software, create or log into your account, or open or interact with any Labtrac application.
                </p>
                        <p>We also collect data about the devices and computers you use to access the Services, including IP addresses, browser type, language, operating system, Labtrac or mobile device information (including device and application identifiers), the referring web page, pages visited, location (depending on the permissions you have granted us), and cookie information.</p>
                        <h3>Health and Other Special Categories of Personal Data</h3>
                        <p>
                            To the extent that information we collect is health data or another special category of personal data subject to the European Union’s General Data Protection Regulation (“GDPR”), we ask for your explicit consent to process the data. We obtain this consent separately when you take actions leading to our obtaining the data, for example, when you sign into your account, grant us access to your business or activity data from another service.
                            You can use your account settings and tools to withdraw your consent at any time, including by stopping use of a feature, removing our access to a third-party service, or deleting your data or your account.
                </p>
                    </div>

                    <h2 id="how-we-use-info">How We Use Information</h2>
                    <p class="heading-paragraph">We use the information we collect for the following purposes.</p>

                    <div class="tabbed">
                        <h3>Provide and Maintain the Services</h3>
                        <p>Using the information we collect, we are able to deliver the Services to you and honor our <a href="/en/Legal/terms">Terms of Service</a> contract with you. For example, we need to use your information to provide you with your Labtrac dashboard tracking your business performance and financial trends, activity, and other trends; to enable the collaboration features of the Services; and to give you customer support.</p>
                        <p>For the Services’ collaboration features, we may use your information to help you find and connect with other users and to allow other users to find and connect with you. For example, your account contact information allows other users to add you as a Laboratory. When another user has your name, email or phone number in their contact list or in their business network on a connected service, we show that user that you are a user of the Services.</p>
                        <h3>Improve, Personalize, and Develop the Services</h3>
                        <p>We use the information we collect to improve and personalize the Services and to develop new ones. For example, we use the information to troubleshoot and protect against errors; perform data analysis and testing; conduct research and surveys; and develop new features and Services.</p>
                        <p>When you allow us to collect precise location information, we use that information to provide and improve features of the Services such as recording where a workflow item took place or mapping an activity.</p>
                        <p>We also use your information to make inferences and show you more relevant content. Here are some examples:</p>
                        <ul>
                            <li>Information like your address, company size, customers, turnover and products you sell allows us to improve the accuracy of your daily financial and activity statistics like the number of orders you completed and the value of business a dentist has given you.</li>
                            <li>Based on your production data, we may make inferences about your workflow patterns and provide you with customized insights to help you improve your business.</li>
                            <li>We may personalize financial and productivity activity goals for you based on the goals you previously set and your historical financial or activity data.</li>
                        </ul>
                        <h3>Communicate with You</h3>
                        <p>
                            We use your information when needed to send you Service notifications and respond to you when you contact us.
                            We also use your information to promote new features or products that we think you would be interested in.
                            You can control marketing communications and most Service notifications by using your notification preferences in account settings or via the “Unsubscribe” link in an email.
                </p>
                        <h3>Promote Safety and Security</h3>
                        <p>We use the information we collect to promote the safety and security of the Services, our users, and other parties. For example, we may use the information to authenticate users, facilitate secure payments, protect against fraud and abuse, respond to a legal request or claim, conduct audits, and enforce our terms and policies.</p>
                    </div>
                    <p>We use cookies and similar technologies for the purposes described above. For more information, please read our <a href="/en/Legal/cookie">Cookie Use</a> statement.</p>
                    <p>For personal data subject to the GDPR, we rely on several legal bases to process the data. These include when you have given your consent, which you may withdraw at any time using your account settings and other tools; when the processing is necessary to perform a contract with you, like the <a href="/legal/terms-of-service">Terms of Service</a>; and our legitimate business interests, such as in improving, personalizing, and developing the Services, marketing new features or products that may be of interest, and promoting safety and security as described above.</p>
                    <h2 id="how-info-is-shared">How Information Is Shared</h2>
                    <p class="heading-paragraph">We do not share your personal information except in the limited circumstances described below.</p>
                    <div class="tabbed">
                        <h3>When You Agree or Direct Us To Share</h3>
                        <p>
                            You may direct us to disclose your information to others, such as when you use our community features like the forums, feature request board, and other social tools. For certain information, we provide you with privacy preferences in &gt;account settings and other tools to control how your information is visible to other users of the Services.
                </p>
                        <h3>For External Processing</h3>
                        <p>We transfer information to our corporate affiliates, service providers, and other partners who process it for us, based on our instructions, and in compliance with this policy and any other appropriate confidentiality and security measures. These partners provide us with services globally, including for customer support, information technology, payments, sales, marketing, data analysis, research, and surveys.</p>
                        <h3>For Legal Reasons or To Prevent Harm</h3>
                        <p>We may preserve or disclose information about you to comply with a law, regulation, legal process, or governmental request; to assert legal rights or defend against legal claims; or to prevent, detect, or investigate illegal activity, fraud, abuse, violations of our terms, or threats to the security of the Services or the physical safety of any person.</p>
                        <div class="tabbed-no-margin-top">
                            <p>Please note: Our policy is to notify you of legal process seeking access to your information, such as search warrants, court orders, or subpoenas, unless we are prohibited by law from doing so. In cases where a court order specifies a non-disclosure period, we provide delayed notice after the expiration of the non-disclosure period. Exceptions to our notice policy include exigent or counterproductive circumstances, for example, when there is an emergency involving a danger of death or serious physical injury to a person.</p>
                        </div>
                    </div>
                    <p>
                        We may share non-personal information that is aggregated or de-identified so that it cannot reasonably be used to identify an individual.
                        We may disclose such information publicly and to third parties, for example, in public reports about financial performance and activity in the dental sector, to partners under agreement with us, or as part of the community benchmarking information we provide to users of our subscription services.
            </p>
                    <p>If we are involved in a merger, acquisition, or sale of assets, we will continue to take measures to protect the confidentiality of personal information and give affected users notice before transferring any personal information to a new entity.</p>
                    <h2 id="your-rights-to-access">Your Rights To Access and Control Your Personal Data</h2>
                    <p>We give you <a href="/settings" data-ignore-localize="">account settings</a> and tools to access and control your personal data, as described below, regardless of where you live. If you live in the European Economic Area, United Kingdom, and Switzerland (the “Designated Countries”), you have a number of legal rights with respect to your information, which your account settings and tools allow you to exercise, as outlined below.</p>
                    <p><strong>Accessing and Exporting Data.</strong> By logging into your account, you can access much of your business information, including your dashboard with your daily financial and activity statistics. Using your account settings, you can also download information in a commonly used file format, including data about your activities, orders, sales and remakes.</p>
                    <p><strong>Editing and Deleting Data.</strong> Your account settings let you change and delete your business information. For instance, you can edit or delete the profile data you provide and delete your account if you wish.</p>
                    <div class="tabbed-no-margin-top">
                        <p>If you choose to delete your account, please note that while most of your information will be deleted within 30 days, it may take up to 90 days to delete all of your information, like the data recorded by your Labtrac system and other data stored in our backup systems. This is due to the size and complexity of the systems we use to store data. We may also preserve data for legal reasons or to prevent harm, including as described in the <a href="#how-info-is-shared">How Information Is Shared</a> section.</p>
                    </div>
                    <p>
                        <strong>Objecting to Data Use.</strong> We give you account settings and tools to control our data use.
                For example, through your privacy settings, you can limit how your information is visible to other users of the Services;
                using your notification settings, you can limit the notifications you receive from us;
                and under your application settings, you can revoke the access of third-party applications that you previously connected to your Labtrac account.
            </p>
                    <p>
                        If you live in a Designated Country, in certain circumstances, you can object to our processing of your information based on our legitimate interests, including as described in the <a href="#how-we-use-info">How We Use Information</a> section. You have a general right to object to the use of your information for direct marketing purposes.
                Please see your notification settings to control our marketing communications to you about Labtrac products. Please also review our <a href="/legal/cookie-policy">Cookie Use</a> statement for your options to control how we and our partners use cookies and similar technologies for advertising.
            </p>
                    <p><strong>Restricting or Limiting Data Use.</strong> In addition to the various controls that we offer, if you reside in a Designated Country, you can seek to restrict our processing of your data in certain circumstances. Please note that you can always delete your account at any time.</p>
                    <h2 id="data-retention">Data Retention</h2>
                    <p>
                        We keep your account information, like your name, email address, and password, for as long as your account is in existence because we need it to operate your account.
                        In some cases, when you give us information for a feature of the Services, we delete the data after it is no longer needed for the feature.
                        For instance, when you provide your contact list for finding dentists on the Services, we delete the list after it is used for adding contacts.
                        We keep other information, like your financial or activity data, until you use your account settings or tools to delete the data or your account because we use this data to provide you with your personal statistics and other aspects of the Services.
                We also keep information about you and your use of the Services for as long as necessary for our legitimate business interests, for legal reasons, and to prevent harm, including as described in the <a href="#how-we-use-info">How We Use Information</a> and <a href="#how-info-is-shared">How Information Is Shared</a> sections.
            </p>
                    <h2 id="analytics-and-advertising">Analytics and Advertising Services Provided by Others</h2>
                    <p>
                        We work with partners who provide us with analytics and advertising services.
                        This includes helping us understand how users interact with the Services, serving ads on our behalf across the internet, and measuring the performance of those ads.
                        These companies may use cookies and similar technologies to collect information about your interactions with the Services and other websites and applications.
                To learn more and about your privacy choices, please read our <a href="/en/Legal/Cookie">Cookie Use</a> statement.
            </p>

                    <h2 id="info-security">Information Security</h2>
                    <p>
                        We work hard to keep your data safe. We use a combination of technical, administrative, and physical controls to maintain the security of your data.
                        This includes using Transport Layer Security (“TLS”) to encrypt many of our Services. No method of transmitting or storing data is completely secure, however.
                If you have a security-related concern, please contact <a href="mailto:support@smilesolver.com">Customer Support</a>.
            </p>

                    <h2 id="changes-to-this-policy">Changes to This Policy</h2>
                    <p>
                        We will notify you before we make material changes to this policy and give you an opportunity to review the revised policy before deciding if you would like to continue to use the Services.
                        You can review previous versions of the policy in our archive.
            </p>
                    <h2 id="contact-us">Who We Are and How To Contact Us</h2>
                    <p>If you have questions, suggestions, or concerns about this policy, or about our use of your information, please contact us at <a href="mailto:privacy@smilesolver.com">privacy@smilesolver.com</a>.</p>
                    <p>
                        If you live in the European Economic Area, United Kingdom, or Switzerland, then Labtrac Solutions Limited, an UK company, controls your personal data and provides you with the Services. If you are seeking to exercise any of your statutory rights, please contact us at <a href="mailto:support@smilesolver.com">support@smilesolver.com</a>. You may also contact us at:
                    </p>
                    <p class="contact-address">
                        <div>Smilesolver Technologies Limited</div>
                        <div>Privacy Policy</div>
                        <div>17 The Downs, Altrincham</div>
                        <div>Cheshire WA14 2QD, GB</div>
                    </p>


                </div>
            </section>
        </div>
    </Layout>
)

export default PrivacyPage